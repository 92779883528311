import axios from "axios";
import { useAlert } from "react-alert";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi, GOOGLE_CLIENT_ID } from "../../constants/constants";

export const COMPANY_DATA = "COMPANY_DATA";
export const JOBPROFILE_DATA = "JOBPROFILE_DATA";
export const JOBROUND_DATA = "JOBROUND_DATA";
export const ALL_ASSIGNMENT = "ALL_ASSIGNMENT";
export const ASSIGNMENT_FILTER = "ASSIGNMENT_FILTER";

export const ASSIGNMENT_DETAILS = "ASSIGNMENT_DETAILS";
export const DELETE_ASSIGNMENT = "DELETE_ASSIGNMENT";
export const INTERVIEW_STUDENTS = "INTERVIEW_STUDENTS";
export const INTERVIEW_ALL = "INTERVIEW_ALL";
export const INTERVIEW_LIST = "INTERVIEW_LIST";
export const ALL_ASSIGNMENT1 = "ALL_ASSIGNMENT1";

export const CompanyGet = (
  alert,
  history,
  location,
  url = "api/assignment/company_job_dropdown/"
) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: COMPANY_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const jobProfileGet = (alert, history, location, url) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: JOBPROFILE_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const interviewGet = (
  setLoading,
  alert,
  history,
  location,
  collegeid,
  date
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/interview/interview_calendar/?college_id=${collegeid}&date=${date}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res?.data?.success) {
      res?.data?.data?.forEach((element) => {
        // if (!element.start_date && !element.end_date) {
        element["startDate"] = new Date(element.start_date);
        element["endDate"] = new Date(element.end_date);
        // }
      });

      dispatch({
        type: INTERVIEW_ALL,
        payload: res?.data?.data,
      });
    } else {
      alert.error(res?.data?.error);
    }
    setLoading(false);
  };
};

export const interviewGetGroup = (
  setLoading,
  alert,
  history,
  location,
  checkSelectedGroup,
  date
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${
          BackendBaseApi.SANCHALAN
        }api/interview/interview_calendar/?${checkSelectedGroup()}&date=${date}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    res.data.data.forEach((element) => {
      // if (!element.start_date && !element.end_date) {
      element["startDate"] = new Date(element.start_date);
      element["endDate"] = new Date(element.end_date);
      // }
    });
    if (res.data.success) {
      setLoading(false);

      dispatch({
        type: INTERVIEW_ALL,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const interviewList = (
  setLoading,
  alert,
  history,
  location,
  collegeid,
  job_id,
  date
) => {
  return async (dispatch) => {
    setLoading(true);
    var query_p = "";
    if (date) {
      query_p += "&date=" + date;
    }
    if (job_id) {
      query_p += "job_id=" + job_id;
    }
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/interview/interview_list/?${query_p}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      dispatch({
        type: INTERVIEW_LIST,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const jobRoundGet = (alert, history, location, jobId) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/assignment/job_round_list/?job_id=${jobId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      res.data.data.forEach((element) => {
        if (!element.name) {
          element["name"] = "";
        }
      });
      dispatch({
        type: JOBROUND_DATA,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const getAllassignment = (setLoading, alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/assignment/company_assignment/`, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });
    res.data.data.table_assignment.forEach((element) => {
      element["id"] = element.job_id;
    });
    if (res.data.success) {
      setLoading(false);
      dispatch({
        type: ALL_ASSIGNMENT,
        payload: res.data.data.table_assignment,
      });
      dispatch({
        type: ALL_ASSIGNMENT1,
        payload: res.data.data.table_assignment,
      });
      dispatch({
        type: ASSIGNMENT_FILTER,
        payload: res.data.data,
      });
    } else {
      setLoading(false);
    }
  };
};

export const deleteAssignment = (
  alert,
  history,
  location,
  assignmentid,
  data
) => {
  return async (dispatch) => {
    const res = await axios
      .delete(
        `${BackendBaseApi.SANCHALAN}api/assignment/job_assignment/${assignmentid}/`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      const filteredData = data.filter((items) => items.id !== assignmentid);
      dispatch({
        type: ASSIGNMENT_DETAILS,
        payload: filteredData,
      });
      alert.success(res?.data?.data?.message);
    } else {
      alert.error(res?.data?.error);
    }
  };
};

export const deleteInterview = (
  alert,
  history,
  location,
  interviewid,
  interviewData
) => {
  return async (dispatch) => {

    const delInterview = async () => {
      const res = await axios
                  .delete(
                    `${BackendBaseApi.SANCHALAN}api/interview/interview_schedule/${interviewid}/`,
                    {
                      withCredentials: true,
                    }
                  )
                  .catch((error) => {
                    checkForToken(error, history, location, alert);
                    throw error;
                  });
                if (res.data.success) {
                  const filteredData = interviewData.filter(
                    (items) => items.id !== interviewid
                  );
                  dispatch({
                    type: INTERVIEW_LIST,
                    payload: filteredData,
                  });
                  alert.success(res?.data?.data?.message);
                } else {
                  alert.error(res?.data?.error);
                }
    };
    delInterview();
  };
};

export const assignmentDetails = (
  setLoading,
  alert,
  history,
  location,
  jobid
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/assignment/job_assignment/?job_id=${jobid}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      setLoading(false);
      dispatch({
        type: ASSIGNMENT_DETAILS,
        payload: res.data.data,
      });
    } else {
      setLoading(false);
    }
  };
};

export const getListofStudents = (alert, history, location, jobid, round, setStudentList= () => {}) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/interview/applied_student_list/?job_id=${jobid}&round_id=${round}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });
    if (res.data.success) {
      dispatch({
        type: INTERVIEW_STUDENTS,
        payload: res.data.data,
      });
      setStudentList(res.data.data);
    } else {
    }
  };
};

export const createAssignment = (
  setLoading,
  data,
  alert,
  history,
  location
) => {
  return async (dispatch) => {
    // setLoading(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/assignment/job_assignment/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      setLoading(false);
      alert.success("Created Successfully");
      history.push("assignments");
    } else {
      setLoading(false);
      alert.error("Some Error Occurred!!!");
    }
    // setLoading(false);
  };
};

export const createInterview = (
  setLoading,
  data,
  alert,
  history,
  location,
  fromTrack,
  handleClose
) => {
  return async (dispatch) => {

    try {
      
      const scheduleInterview = async () => {
        try {
          const res = await axios.post(
            `${BackendBaseApi.SANCHALAN}api/interview/interview_schedule/`,
            data,
            {
              withCredentials: true,
            }
          );

          if (res.data.success) {
            alert.success("Interview Created Successfully");
            if (!fromTrack) history.push("interview-calendar");
            else handleClose();
          } else {
            alert.error("Some Error Occurred!!!");
          }
        } catch (error) {
          
          setLoading(false);
          checkForToken(error, history, location, alert);
          throw error;
        } finally {
        }
      };
      scheduleInterview();

    } catch (error) {
      setLoading(false);
      alert.error("An error occurred while scheduling the interview.");
    } finally {
    }
  };
};

export const changeStateInterview = (dispatcher, data) => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
  };
};